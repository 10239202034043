<template>
  <v-container fluid class="pa-0 ma-0">
    <v-layout align-center justify-center v-if="loading" class="mt-2">
      <a-spin tip="Loading..."> </a-spin>
    </v-layout>
    <template v-else>
      <v-toolbar color="#f0f0f0">
        <v-toolbar-title>
          <strong>{{ shiyoData.shiyoNumber }}</strong>
        </v-toolbar-title>
        <v-btn dark rounded color="#1565c0" @click="CloseTab()" class="ml-5">
          <v-icon>mdi-arrow-left</v-icon>Back
        </v-btn>
      </v-toolbar>

      <v-card tile flat id="print">
        <hr />
        <!-- <v-layout pt-4 pl-4>
              <p style="font-size: 20px; padding-top: 0; font-weight: bold;">
                件名:
              </p>
              <p
                style="font-size: 20px; padding-top: 0; font-weight: bold;"
                v-html="shiyoData.data[shiyoData.version - 1].productName.toUpperCase()"
              >
              </p>
          </v-layout> -->
        <v-toolbar dense flat>
          <v-row>
            <v-col class="d-flex">
              <h2 class="ml-2">BEFORE</h2>
              <v-spacer></v-spacer>
              <h3 class="mt-2">legend:</h3>
              <v-icon style="color:pink">mdi-checkbox-blank</v-icon>
              <h3 class="mt-2 mr-2">deleted</h3>
            </v-col>
            <v-col class="d-flex">
              <h2>AFTER</h2>
              <v-spacer></v-spacer>
              <h3 class="mt-2">legend:</h3>
              <v-icon style="color:lightgreen">mdi-checkbox-blank</v-icon>
              <h3 class="mt-2 mr-8">changes</h3>
            </v-col>
          </v-row>
        </v-toolbar>
        <hr />
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6">
              <v-card
                max-width="1000"
                class="mt-1"
                v-if="shiyoUpdateDetails.before"
                style="overflow: auto;"
              >
                <!-- <v-flex
                  class="ma-2"
                  v-for="(data, index) in shiyoUpdateDetails.before"
                  :key="index"
                > -->
                <v-flex>
                  <div max-width="1000" class="rulebookBefore">
                    <div v-html="shiyoUpdateDetails.before"></div>
                  </div>
                </v-flex>
              </v-card>
              <v-card
                max-width="1000"
                class="mt-1"
                v-else
                style="overflow: auto;"
              >
                <v-flex
                  class="ma-2"
                  v-for="(data, index) in shiyoData.htmlContent"
                  :key="index"
                >
                  <!-- <v-flex> -->
                  <div max-width="1000" class="rulebookBefore">
                    <div v-html="data"></div>
                  </div>
                </v-flex>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card
                width="100%"
                class="mt-1"
                v-if="shiyoUpdateDetails.after"
                style="overflow : auto;"
              >
                <!-- <v-flex
                  class="ma-2"
                  v-for="(data, index) in shiyoUpdateDetails.after"
                  :key="index"
                > -->
                <v-flex>
                  <div width="100%" class="rulebookAfter">
                    <div v-html="shiyoUpdateDetails.after"></div>
                  </div>
                </v-flex>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- {{ `${shiyoUpdateDetails.before}` }} -->
      </v-card>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";
// import Editor from "@tinymce/tinymce-vue";
// import tinymce from "tinymce/tinymce"
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
// import theme from '!!raw-loader!tinymce/themes/silver/theme.min.js'
export default {
  title() {
    return `@Shiyo Comparing ${this.$route.params.id.substring(0, 9)}`;
  },

  name: "newPage",
  props: ["search"],
  data() {
    return {
      tiny_init: {
        object_resizing: false,
        inline: true,
        readonly: 1,
        toolbar: false,
        menubar: false,
        visual: false,
        skin: false,
        content_css: false,
        content_style:
          [contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
        // content_style: [contentCss, contentUiCss].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',
      },
      loading: false,
      thingToCopy: `sk-manual@ichijo.co.jp`,
      title_spacer: "　",
      shiyoUpdateDetails: {},
      comparedShiyo: [],
      shiyoData: {},
    };
  },
  created() {
    setTimeout(() => {
      this.createData();
    }, 600);

    // let url = "";
    // let url1 = "";
    // let search = "";
    // if (this.$route.query.search != "") {
    //   search = this.$route.query.search;
    // }
    // this.loading = true;
    // url = `${this.api}shiyo/getcomparing?id=${this.$route.params.id}&search=${search}`;
    // // url = `${this.api}shiyo/get_shiyo/${this.shiyoData._id}`;
    // url1 = `${this.api}shiyo/getcomparingSpecificData?id=${this.$route.params.id}&search=${search}`;
    // axios
    //   .get(url1)
    //   .then((request) => {
    //     console.log(request.data);
    //     this.shiyoData = request.data;
    //   })
    //   .then(() => {
    //     axios
    //       .get(url)
    //       .then(async (res) => {
    //         console.log("line 370 ", res.data);
    //         document.title = `${res.data.shiyoNumber}-${res.data.productName}`;
    //         await this.$set(
    //           this.shiyoUpdateDetails,
    //           "after",
    //           Object.assign(
    //             {},
    //             res.data.changes.map((rec) => {
    //               let parser = new DOMParser();
    //               let doc = parser.parseFromString(rec, "text/html");
    //               let maxLen = rec.length;
    //               let delTagLen = doc.getElementsByTagName("del").length;
    //               let result = rec
    //                 .replace(/id="p/gi, 'id="ap')
    //                 .replace(/#p/gi, "#ap");
    //               for (let i = 0; i < delTagLen; i++) {
    //                 let start = result.indexOf("<del ");
    //                 let end = result.indexOf("</del>") + 6;
    //                 result =
    //                   result.slice(0, parseInt(start)) +
    //                   result.slice(parseInt(end), maxLen);
    //               }
    //               return result;
    //             })
    //           )
    //         );
    //         await this.$set(
    //           this.shiyoUpdateDetails,
    //           "before",
    //           Object.assign(
    //             {},
    //             res.data.deleted.map((rec) => {
    //               let parser = new DOMParser();
    //               let doc = parser.parseFromString(rec, "text/html");
    //               let maxLen = rec.length;
    //               let insTagLen = doc.getElementsByTagName("ins").length;
    //               let result = rec;
    //               for (let i = 0; i < insTagLen; i++) {
    //                 let start = result.indexOf("<ins ");
    //                 let end = result.indexOf("</ins>") + 6;
    //                 result =
    //                   result.slice(0, parseInt(start)) +
    //                   result.slice(parseInt(end), maxLen);
    //               }
    //               return result;
    //             })
    //           )
    //         );
    //       })
    //       .then(() => {
    //         setTimeout(() => {
    //           let delTagLen = document.getElementsByTagName("del").length;
    //           let insTagLen = document.getElementsByTagName("ins").length;
    //           let delTag = document.getElementsByTagName("del");
    //           let insTag = document.getElementsByTagName("ins");
    //           // }
    //           for (let i = 0; i < delTagLen; i++) {
    //             if (
    //               document
    //                 .getElementsByTagName("del")
    //                 [i].querySelector("img") !== null
    //             ) {
    //               delTag[
    //                 i
    //               ].style.cssText = `${delTag[i].style.cssText} filter: drop-shadow(0 0 0.75rem red);`;
    //             } else {
    //               delTag[
    //                 i
    //               ].style.cssText = `${delTag[i].style.cssText} background-color: pink;`;
    //             }
    //           }
    //           for (let x = 0; x < insTagLen; x++) {
    //             if (
    //               document
    //                 .getElementsByTagName("ins")
    //                 [x].querySelector("img") !== null
    //             ) {
    //               insTag[
    //                 x
    //               ].style.cssText = `${insTag[x].style.cssText} filter: drop-shadow(0 0 0.75rem green);`;
    //             } else {
    //               insTag[
    //                 x
    //               ].style.cssText = `${insTag[x].style.cssText} background-color: lightgreen;`;
    //             }
    //           }
    //         }, 600);
    //       });
    //   });
    // this.loading = false;
    // const url1 = `http://10.169.140.133:6002/shiyo/get/comparing?id=${this.$route.params.id}&search=${this.$route.params.searchText}`;

    // }
  },

  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
  },

  methods: {
    CloseTab() {
      // if (this.shiyoData.deleted == true) {
      //   this.$router.push(`/deletedshiyo`);
      // }else{
      //   window.close();
      // }
      window.close();
    },
    createData() {
      const urlOrigVer = `${this.api}/shiyo/getSpecificShiyoCompare/${this.$route.params.id}`;
      const urlPrevVer = `${this.api}/shiyo/getSpecificShiyoCompare/${this.$route.params.id2}`;
      let compareDataUrl = `${this.api}/shiyo/processHtmlContent`;
      let idTwo = this.$route.params.id2;
      let dataOne;
      let dataTwo;
      axios.get(urlOrigVer).then((res) => {
        if (res.data) {
          this.shiyoData = res.data;
          // console.log(res.data.htmlContent[0]);
          dataOne = res.data;

          this.shiyoData.htmlContent = dataOne.htmlContent
            // .split("<!-- pagebreak -->")
            .map((rec, i) => {
              return `<div id=p${i + 1}>${rec}</div>`;
            })
            .map((rec) => {
              return rec.replace(
                // /http:\\\\10.11.1.59:2929\\uploads\\/g,
                /http:\\\\hrdapps48:5010\\uploads\\image\\froala\\test\\/g,
                "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
              );
            });
        }
      });
      axios.get(urlPrevVer).then((res) => {
        if (res.data) {
          dataTwo = res.data;
        }
      });

      if (idTwo) {
        let obj = {
          before: dataTwo[0].htmlContent,
          after: dataOne[0].htmlContent,
        };
        axios.post(compareDataUrl, obj).then(async (res) => {
          if (res.data) {
            let comparedData = res.data;
            // .map((rec, i) => {
            //   return `<div id=p${i + 1}>${rec}</div>`;
            // })
            // .map((rec) => {
            //   return rec.replace(
            //     // /http:\\\\10.11.1.59:2929\\uploads\\/g,
            //     /http:\\\\hrdapps48:5010\\uploads\\image\\froala\\test\\/g,
            //     "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
            //   );
            // });
            // console.log(comparedData);
            var tempElementDel = document.createElement("div");
            var tempElementIns = document.createElement("div");
            tempElementDel.innerHTML = comparedData;
            tempElementIns.innerHTML = comparedData;

            // Find all <del> elements
            var delElements = tempElementDel.querySelectorAll("del");
            var insElements = tempElementIns.querySelectorAll("ins");

            // Remove the content inside <del> elements and the <del> tags themselves
            for (var i = 0; i < delElements.length; i++) {
              var delElement = delElements[i];
              delElement.parentNode.removeChild(delElement);
            }
            for (var o = 0; o < insElements.length; o++) {
              var insElement = insElements[o];
              insElement.parentNode.removeChild(insElement);
            }
            // Get the modified HTML string
            var modifiedHtmlStringDel = tempElementDel.innerHTML;
            var modifiedHtmlStringIns = tempElementIns.innerHTML;

            this.$set(this.shiyoUpdateDetails, "before", modifiedHtmlStringIns);
            this.$set(this.shiyoUpdateDetails, "after", modifiedHtmlStringDel);

            this.shiyoUpdateDetails.before = this.shiyoUpdateDetails.before.replace(
              /http:\\\\hrdapps48:5010\\uploads\\image\\froala\\test\\/g,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
            );
            this.shiyoUpdateDetails.after = this.shiyoUpdateDetails.after.replace(
              /http:\\\\hrdapps48:5010\\uploads\\image\\froala\\test\\/g,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
            );
            console.log(this.shiyoUpdateDetails);
          }
        });
      }
    },

    roomPart(item) {
      if (Array.isArray(item)) {
        let string = " ";
        for (let text of item) {
          string += text + "、　";
        }
        // string = string.substring(string.length, 2)
        return string;
      } else {
        return item;
      }
    },
  },
};
</script>

<style scoped>
.viewed {
  background-color: #daf5b3;
}
@media only print {
  body {
    position: static;
  }

  div.v-toolbar * {
    display: none !important;
  }
  div.v-toolbar__content * {
    display: none !important;
  }
  nav.v-toolbar.elevation-0.theme--light {
    display: none !important;
  }

  div.ant-tabs-nav-scroll * {
    display: none !important;
  }
  div.v-card.v-card--flat.v-sheet.theme--light {
    margin: 0mm;
    padding: 0mm;
    overflow-y: visible !important;
    position: relative;
  }
}
</style>
